<template>
  <div v-if="!submitted" class="decision">
      <div class="question">
        <h1> I really need help deciding...</h1>
        <h1> {{ question }} </h1>
      </div>
      <div class="divider"> - - - - - </div>
      <div class="options">
        <Option v-for="(option, index) in options" :key="option"
            :description="option"
            :selected="index === selectedIndex"
            :index="index"
            @selection="changeSelection"/>
      </div>
      <div class="btn-container">
        <div class="center">
          <button class="btn" @click="onSubmit" :class="{'disabled' : noSelection}">
            <svg viewBox="0 0 180 60" class="border">
              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
            </svg>
            <span> SEND IT </span>
          </button>
        </div>
      </div>
  </div>
  <div v-else class="chosen">
    <h1 > Thanks! </h1>
    <h2> Check back later to see if I took your advice or not... </h2>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Option from './Option.vue';

@Options({
    components: {
        Option
    },
})
export default class Decision extends Vue {
  @Prop({ required: true })
  protected question!: string;

  @Prop({ required: true })
  protected options!: string[];

  protected selectedIndex = -1;
  protected submitted = false;
  protected unselectedSubmits = 0;

  protected changeSelection(newIndex: number): void {
      this.selectedIndex = newIndex;
  }

  protected get noSelection(): boolean {
    return this.selectedIndex === -1;
  }

  protected onSubmit(): void {
    if (this.noSelection) {
      console.log(`invalid submissino attempts: ${this.unselectedSubmits}`);
      this.unselectedSubmits++;
      if (this.unselectedSubmits === 5) {
        alert('Make a selection bud....');
      } else if (this.unselectedSubmits === 10) {
        alert('Are you hourly?');
      }
      return;
    }
    this.submitted = true;
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chosen {
  color: white;
}

.decision {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.question {
    font-style: italic;
}

.options {
  margin-top: 2rem;

  :hover {
  }
}

.btn-container {
  margin-top: 2rem;
  width: 11rem;
}
.center {
  position: absolute;
}
.btn {
  background: transparent;
  border: .09rem solid #91C9FF;
  border-radius: .27rem;
  cursor: pointer;
  height: 3.7rem;
  outline: none;
  transition: 1s ease-in-out;
  width: 11rem;

  &.disabled {
    background: grey;
    border: .09rem solid #ee9595;
    cursor: help;
    opacity: .3;
    transition: none;
  }
}

.border {
  fill: none;
  height: 3.7rem;
  left: 0;
  position: absolute;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  top: 0;
  transition: 1s ease-in-out;
  width: 11rem;
}

.btn:hover:not(.disabled) {
  background: #957cae;
  transition: 1s ease-in-out;
}

.btn:hover:not(.disabled) svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  font-size: 1.12rem;
  font-weight: 100;
}

</style>
