
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Option from './Option.vue';

@Options({
    components: {
        Option
    },
})
export default class Decision extends Vue {
  @Prop({ required: true })
  protected question!: string;

  @Prop({ required: true })
  protected options!: string[];

  protected selectedIndex = -1;
  protected submitted = false;
  protected unselectedSubmits = 0;

  protected changeSelection(newIndex: number): void {
      this.selectedIndex = newIndex;
  }

  protected get noSelection(): boolean {
    return this.selectedIndex === -1;
  }

  protected onSubmit(): void {
    if (this.noSelection) {
      console.log(`invalid submissino attempts: ${this.unselectedSubmits}`);
      this.unselectedSubmits++;
      if (this.unselectedSubmits === 5) {
        alert('Make a selection bud....');
      } else if (this.unselectedSubmits === 10) {
        alert('Are you hourly?');
      }
      return;
    }
    this.submitted = true;
  }

}
