
import { Options, Vue } from 'vue-class-component';
import Decision from './components/Decision.vue';

@Options({
  components: {
    Decision,
  },
})
export default class App extends Vue {
  protected options = ['Yeah, fuck it... why not?', 'Are you fucking stupid? No.'];
}
