<template>
  <Decision
    question="Should I go home between now and christmas?"
    :options="options" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Decision from './components/Decision.vue';

@Options({
  components: {
    Decision,
  },
})
export default class App extends Vue {
  protected options = ['Yeah, fuck it... why not?', 'Are you fucking stupid? No.'];
}
</script>

<style>
html,
body {
  height: 100%;
  background-color: #2c3e50;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
