
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Options({
    components: {
        FontAwesomeIcon
    },
    emits: ['selection'],
})
export default class Option extends Vue {
  @Prop({ required: true })
  protected description!: string;

  @Prop({ required: true })
  protected selected!: boolean;

  @Prop({ required: true })
  protected index!: number;

  created() {
      library.add(faCheck);
  }

  protected onClick() {
      let index = this.index;
      if (this.selected) {
          // unselect
          index = -1;
      }
      this.$emit('selection', index);
  }
}
