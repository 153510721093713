<template>
  <div class="option-container"
    :class="{'selected': selected}"
    @click="onClick">
      <div class="icon">
        <FontAwesomeIcon v-if="selected"
            icon="check"/>
      </div>
      <h1> {{ description }} </h1>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Options({
    components: {
        FontAwesomeIcon
    },
    emits: ['selection'],
})
export default class Option extends Vue {
  @Prop({ required: true })
  protected description!: string;

  @Prop({ required: true })
  protected selected!: boolean;

  @Prop({ required: true })
  protected index!: number;

  created() {
      library.add(faCheck);
  }

  protected onClick() {
      let index = this.index;
      if (this.selected) {
          // unselect
          index = -1;
      }
      this.$emit('selection', index);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.option-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;

    /* prevents selecting text when clicking an answer */
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */

    :hover{
        text-decoration: underline;
    }
}

.icon {
    width: 45px;
}

.selected {
    color: #957cae;

    h1:hover {
        color: #665875;
    }
}
</style>
